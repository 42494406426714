import React from 'react'

class Pagination extends React.Component {

  getPageLink(pagepath,page,i) {
    if (page === i ) {
      return (
        <span key={i.toString()} className="pagination__link pagination__link--active">{i}</span>
      )
    } else {
      return (
        <a key={i.toString()} href={pagepath+(i>1?i:'')} title={"Vai a pagina "+i} className="pagination__link">{i}</a>
      )
    }
  }

  render() { 
    const {page, pagesSum, pagepath} = this.props;
    var prevPage = page - 1;
    let pagelink = [];
    for (let i = 1; i <= pagesSum; i++) {
      pagelink.push(this.getPageLink(pagepath,page,i));
    }

    return (
      <div className="row">
        <div className="col-12">
          <div className="pagination">
            {page>1 && 
              <a href={pagepath+(prevPage===1?'':prevPage)} title="Vai alla pagina precedente" className="pagination__link">
                <i className="fa fa-chevron-left" aria-hidden="true"></i>
              </a>
            }
            {pagelink}
            {page<pagesSum && 
              <a href={pagepath+(page+1)} title="Vai alla pagina successiva" className="pagination__link">
                <i className="fa fa-chevron-right" aria-hidden="true"></i>
              </a>
            }
          </div>
        </div>
      </div>
    )
  }

}

export default Pagination;