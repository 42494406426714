import React from 'react'
import utils from '../utility/utils'
import Layout from "../components/layout"
import Pagination from "../components/pagination"
import PropTypes from 'prop-types';


export default function Pages({ pageContext, location }) {
  const { posts, page, pagesSum, pageTitle, pagePath, prevPath, nextPath } = pageContext;
  
  return (
    <Layout location={location}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <section id="news-list" className="section"  aria-labelledby="region1">
              <div className="row">
                <div className="col-12">
                  <h1 id="region1" className="section__title">{pageTitle}</h1>
                </div>
              </div>
              <div className="row">
                {posts.map((item,index) => 
                  <div key={index} className="col-12">
                    <article className="news news--horizontal news--dark clearfix">
                      {!!item.node.immagine &&
                        <figure className="news__figure text-center">
                          <img src={item.node.immagine.file.url} alt="Inaugurazione ospedale" className="news__img" />
                        </figure>
                      }
                      <div className="news__info">
                        <h2 className="news__title">
                          <a href={"/news-ed-eventi/"+item.node.friendlyUrl} title={item.node.titolo} className="news__link">{item.node.titolo}</a>
                        </h2>
                        <div className="news__category">{item.node.argomento}</div>
                        {!!item.node.abstract &&
                          <div className="news__abstract" dangerouslySetInnerHTML={{ __html: item.node.abstract.childMarkdownRemark.html }} >
                          </div>
                        }
                        <span className="news__date">{utils.formatDate(item.node.data)}</span>
                      </div>
                    </article>
                  </div>
                )}
              </div>
              {pagesSum > 1 &&
                <Pagination page={page} pagesSum={pagesSum} pagepath={pagePath} />
              }
            </section>
          </div>
        </div>
      </div>
    </Layout>
  )
}

Pages.propTypes = {
  pageContext: PropTypes.object,
  data: PropTypes.object,
};

